import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import styled, { css } from "styled-components";
import Img from "gatsby-image";
import logoImg from "../images/logo-icon.png";
import { LogoImg } from "../components/Logo";
import AmazonDetailListing from "../components/AmazonListing";
import Header from "../components/Header";
import FancyHeader from "../components/FancyHeader";

import {
  Layout,
  Article,
  Wrapper,
  Button,
  SectionTitle,
  Subline,
  Content,
  Hero,
  LoadingSubline
} from "../components";
import {
  StyledH3,
  SecondaryH2,
  StyledInput
} from "../components/StyledElements";
import TensorFlowComponent from "../tensorflow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs } from "@fortawesome/free-solid-svg-icons";

const IndexPage = ({}) => (
  <Layout>
    <Wrapper>
      <TensorFlowComponent
        render={({
          loadModel,
          model,
          loading,
          getSummary,
          runModel,
          names,
          generating,
          seedText,
          handleSeedTextChange
        }) => {
          return (
            <React.Fragment>
              <div
                css={css`
                  grid-area: header;
                `}
              >
                <FancyHeader />
              </div>
              <Content>
                <LogoImg src={logoImg} alt={"Fantasy Name Machine logo"} />
                <p>
                  Below is our home grown fantasy name generator. As a DM, I've
                  used it in preperation for my Dungeons and Dragons 5th edition
                  campaigns when I hit a mental wall on names, but you could use
                  it for any tabletop rpg campaign. If you like it - check out
                  our <Link to={"/paper-resources"}>paper resources</Link> - it
                  helps support making more of these kinds of things!
                </p>
                <p>
                  Our generator has been utilized in creating names for the new
                  mobile game{" "}
                  <a href={"https://www.gameoffate.com"}>
                    Fate, A Game of Chance
                  </a>
                  . Feel free to use the names you generate here for any
                  non-commercial purpose. Just let people know where you got
                  them from!
                </p>
                <SectionTitle>
                  <StyledInput
                    id={"seedText"}
                    label="Enter seed text below"
                    value={seedText}
                    onChange={handleSeedTextChange}
                  />
                  <Button big onClick={runModel}>
                    <FontAwesomeIcon icon={faCogs} />
                    Generate Names
                  </Button>
                  {loading && <LoadingSubline>LOADING</LoadingSubline>}
                  {generating && <LoadingSubline>algorithming</LoadingSubline>}
                </SectionTitle>
                {names.map(name => {
                  return <div key={name}>{name}</div>;
                })}
              </Content>
            </React.Fragment>
          );
        }}
      />
    </Wrapper>
  </Layout>
);

export default IndexPage;

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      nodes: PropTypes.array.isRequired
    })
  }).isRequired
};

// export const IndexQuery = graphql`
//   query IndexQuery {
//     allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
//       nodes {
//         excerpt(pruneLength: 200)
//         timeToRead
//         fields {
//           slug
//         }
//         frontmatter {
//           title
//           date(formatString: "MM/DD/YYYY")
//           categories
//           primaryColor
//           banner {
//             ...bannerImage640
//           }
//         }
//       }
//     }
//   }
// `;
